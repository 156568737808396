.lonable-asset-details{
    border-radius: 5px;
    box-shadow: 0 2px 4px 0 rgb(0 0 0 / 12%), 0 2px 4px 0 rgb(0 0 0 / 12%);
    padding: 20px;
}
.lonable-asset-details button{
    color: white !important;
    background-color: #3ecf8e !important;
    padding: 5px;
    border: 1px solid #3ecf8e;
}

.lonable-asset-details .signbutton{
    width: auto !important;
}